import React from 'react';

const About = () => (
    <>
    <div id="about">
    <h1>About</h1>
        <p>
                Hi, my name is Jayant!
                I am a final year Systems Design Engineering student
                at the University of Waterloo graduating in April 2024. I am currently seeking full time/new grad roles.  
        </p>
        <p>
                With experience on various software development teams, I have worked with a diverse set of technologies.
                I follow my passion through internships, hackathons, and projects. If you would like to chat,
                feel free to reach out.
        </p>
        <p>
                Outside of my development projects and work, I enjoy coaching swimming as a swim instructor, playing basketball, and table tennis.
                You can also find me hiking in the trails with my dog Oscar, especially during the summer months :).
        </p>
    </div>
   

    </>
);

export default About;