const ExperienceContent = [
    {
        company: 'Tesla',
        title: 'Software Applications Engineer',
        timeline: 'Sep 2022 - Jan 2023',
        imageName: 'tesla.svg'
    },
    {
        company: 'AMD',
        title: 'DevOps Software Engineer',
        timeline: 'Aug - Sep 2022',
        imageName: 'amd.jpeg'
    },
    {
        company: 'Deloitte',
        title: 'Software Engineer and Data Scientist',
        timeline: 'Jan - Apr 2022',
        imageName: 'deloitte.png'
    },
    {
        company: 'BlackBerry',
        title: 'Software Developer',
        timeline: 'May - Aug 2021',
        imageName: 'blackberry.jpg'
    },
    {
        company: 'Oracle',
        title: 'Software Developer and SDET Intern',
        timeline: 'Sep - Dec 2020',
        imageName: 'oracle.png'
    },
    {
        company: 'Government of Canada',
        title: 'IT Full Stack Developer',
        timeline: 'Jan - Apr 2020',
        imageName: 'gov_canada.png'
    },
    {
        company: 'Microsoft',
        title: 'Student Ambassador',
        timeline: 'Apr 2020 - Present',
        imageName: 'microsoft.jpeg'
    },
];

export default ExperienceContent;