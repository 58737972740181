const mediaProfiles = [
    {
        name: 'Email',
        brand: false,
        myURL: 'mailto: jayant.mehta@uwaterloo.ca',
        iconClass: "envelope"
    },
    {
        name: 'GitHub',
        brand: true,
        myURL: 'https://github.com/JayantMehta1',
        iconClass: ['fab', 'github']
    },
    {
        name: 'LinkedIn',
        brand: true,
        myURL: 'https://www.linkedin.com/in/jayant-mehta',
        iconClass: ['fab', 'linkedin']
    },
];

export default mediaProfiles;